import classes from "./Header.module.css";
import { ReactComponent as Arrow } from "../../assets/caret.svg";
import { useState } from "react";
import { authActions } from "../../store/auth";
import { useDispatch, useSelector } from "react-redux";

const Header = (props) => {
  return (
    <header className={classes.header}>
      <h2>Bioportal Admin Dashboard</h2>
      <nav className={classes.navbar}>
        <ul className={classes["navbar-nav"]}>
          <NavItem icon={<Arrow />}>
            <DropdownMenu>

            </DropdownMenu>
          </NavItem>
        </ul>
      </nav>
    </header>
  );
};

const NavItem = (props) => {
  const userState = useSelector((state) => state.auth.userState);
  const [open, setOpen] = useState(false);

  const onClickHandler = (event) => {
    event.preventDefault();
    setOpen(!open);
  };

  return (
    <li className={classes["nav-item"]}>
      <a href="!#" className={!open ? classes["icon-button"] : `${classes["icon-button"]} ${classes["icon-button-active"]}`} onClick={onClickHandler}>
        <div className={classes["icon-menu"]}>
          <h4>{userState.first_name}</h4>
          {props.icon}
        </div>
      </a>
      {open && props.children}
    </li>
  );
};

const DropdownMenu = () => {
  const dispatch = useDispatch()
  
  const onLogoutHandler = (event) => {
    event.preventDefault();
    dispatch(authActions.logout());
  }

  const DropdownItem = (props) => {
    return <a href="!#" role = "button" className={classes["menu-item"]} onClick={props.onClickHandler}>
      <span className={classes["icon-button"]}>{props.leftIcon}</span>
      {props.children}
      <span className={classes["icon-button"]}>{props.rightIcon}</span>
    </a>
  }

  return <div className={classes.dropdown}>
    <DropdownItem>My Profile</DropdownItem>
    <DropdownItem onClickHandler={onLogoutHandler}>Logout</DropdownItem>
  </div>;
};

export default Header;
