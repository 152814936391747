import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/ui";

const useRequest = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const token = useSelector((state) => state.auth.token);

  const sendRequest = useCallback(
    async (requestConfig, applyData) => {
      setIsLoading(true);
      try {
        if (!token) {
          throw new Error("Not Authenticated!");
        }
        const response = await fetch(
          `https://auth.infinity-biologix-services.com${requestConfig.url}`,
          {
            method: requestConfig.method ? requestConfig.method : "GET",
            headers: requestConfig.headers
              ? {
                  Authorization: `Bearer ${token}`,
                  ...requestConfig.headers,
                }
              : { Authorization: `Bearer ${token}` },
            body: requestConfig.body
              ? JSON.stringify(requestConfig.body)
              : null,
          }
        );
        const responseBody = await response.json();
        if (!response.ok) {
          throw new Error(responseBody.detail);
        } else if (applyData) {
          applyData(responseBody);
        }
      } catch (err) {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error fetching data!",
            message: err.message || "Something went wrong!",
          })
        );
        setError(err.message || "Something went wrong!");
      }
    },
    [dispatch, token]
  );
  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useRequest;
