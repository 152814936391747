import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import classes from "./UserTable.module.css";
import { GrEdit } from "react-icons/gr";
import ActivateButton from "../../UI/ActivateButton";

const columns = [
  {
    headerName: "Email",
    field: "email",
    flex: 1,
  },
  {
    headerName: "First Name",
    field: "first_name",
    flex: 1,
  },
  {
    headerName: "Last Name",
    field: "last_name",
    flex: 1,
  },
  {
    headerName: "Lab",
    field: "location",
    flex: 1,
  },
  {
    headerName: "Team",
    field: "team",
    flex: 1,
  },
  {
    headerName: "Role",
    field: "role_name",
    flex: 1,
  },
  {
    headerName: "Last Login",
    field: "last_login",
    flex: 1,
  },
  {
    headerName: "Actions",
    field: "actions",
    flex: 2,
    renderCell: (params) => (
      <strong>
        {params.value.confirmed ? (
          <ActivateButton activate={false} userEmail={params.value.email} />
        ) : (
          <ActivateButton activate={true} userEmail={params.value.email} />
        )}
        <Button
          // variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          className={classes["edit-button"]}
        >
          <Link
            to={{
              pathname: `/users/edit/${params.value.email}`,
              email: params.value.email,
              firstName: params.value.firstName,
              lastName: params.value.lastName,
              location: params.value.location,
              team: params.value.team,
              roleName: params.value.roleName
            }}
          >
            <GrEdit />
          </Link>
        </Button>
      </strong>
    ),
  },
];

export default columns;
