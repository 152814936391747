import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/ui";
import useRequest from "../../hooks/use-request";
import {
  FormInputWrapper,
  FormSubmit,
  FormWrapper,
} from "../../UI/Form/FormItems";

const roleNames = [
  "Submission",
  "Extraction",
  "Comm",
  "PCR",
  "Manager",
  "Superuser",
];

const labNames = ["NJ", "MN"];

const teamNames = ["Bioinformatics", "Unassigned"];

const EditUserForm = (props) => {
  const dispatch = useDispatch();
  const { sendRequest: updateUser } = useRequest();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: props.userData.email,
      firstName: props.userData.firstName,
      lastName: props.userData.lastName,
      location: props.userData.location,
      team: props.userData.team,
      roleName: props.userData.roleName,
    },
  });

  const submitHandler = (data) => {
    updateUser(
      {
        url: `/admin/update-user/${data.email}`,
        method: "PUT",
        headers: {
          Accept: "application/json",
        },
        body: {
          ...(props.userData.email !== data.email && { email: data.email }),
          ...(props.userData.firstName !== data.firstName && {
            first_name: data.firstName,
          }),
          ...(props.userData.lastName !== data.lastName && {
            last_name: data.lastName,
          }),
          ...(props.userData.location !== data.location && {
            location: data.location,
          }),
          ...(props.userData.team !== data.team && { team: data.team }),
          ...(props.userData.roleName !== data.roleName && {
            role_name: data.roleName,
          }),
        },
      },
      (data) => {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Successfully Updated User!",
            message: "",
          })
        );
      }
    );
  };

  console.log(errors.firstName);

  return (
    <FormWrapper
      title="Edit User"
      onSubmitHandler={handleSubmit(submitHandler)}
    >
      <FormInputWrapper label="Email" errorState={errors.email}>
        <input
          type="email"
          {...register("email", {
            required: "*required",
            maxLength: {
              value: 64,
              message: "error message",
            },
          })}
        ></input>
      </FormInputWrapper>
      <FormInputWrapper label="First Name" errorState={errors.firstName}>
        <input
          type="text"
          {...register("firstName", {
            required: "*required",
            maxLength: {
              value: 16,
              message: "error message",
            },
          })}
        ></input>
      </FormInputWrapper>
      <FormInputWrapper label="Last Name" errorState={errors.lastName}>
        <input
          type="text"
          {...register("lastName", {
            required: "*required",
            maxLength: {
              value: 16,
              message: "error message",
            },
          })}
        ></input>
      </FormInputWrapper>
      <FormInputWrapper label="Location" errorState={errors.location}>
        <select
          {...register("location", {
            required: "*required",
          })}
        >
          {labNames.map((labName) => (
            <option key={labName} value={labName}>
              {labName}
            </option>
          ))}
        </select>
      </FormInputWrapper>
      <FormInputWrapper label="Team" errorState={errors.team}>
        <select
          {...register("team", {
            required: "*required",
          })}
        >
          {teamNames.map((teamName) => (
            <option key={teamName} value={teamName}>
              {teamName}
            </option>
          ))}
        </select>
      </FormInputWrapper>
      <FormInputWrapper label="Role" errorState={errors.roleName}>
        <select
          {...register("roleName", {
            required: "*required",
          })}
        >
          {roleNames.map((roleName) => (
            <option key={roleName} value={roleName}>
              {roleName}
            </option>
          ))}
        </select>
      </FormInputWrapper>
      <FormSubmit linkTo="/users" linkLabel="Back" submitLabel="Edit" />
    </FormWrapper>
  );
};

export default EditUserForm;
