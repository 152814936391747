import classes from "./Layout.module.css";
import Sidebar from "./Sidebar";
import Header from "./Header";

const Layout = (props) => {
  return (
    <>
      <div className={classes.layout}>
        <Sidebar></Sidebar>
        <section className={classes.main}>
          <Header></Header>
          <main className={classes["main-content"]}>{props.children}</main>
        </section>
      </div>
    </>
  );
};

export default Layout;
