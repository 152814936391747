import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import { useEffect } from "react";
import classes from "./UserTable.module.css";
import columns from "./UserTableColumns";
import useRequest from "../../hooks/use-request";

const formatDate = (fullDate) => {
  return moment(fullDate).format("lll");
};

const UserTable = (props) => {
  const { setUserDataState, searchState } = props;
  const { sendRequest: fetchAllUsers } = useRequest();

  useEffect(() => {
    const applyData = (data) => setUserDataState(data);
    fetchAllUsers(
      {
        url: "/admin/query-all-users",
        headers: {
          Accept: "application/json",
        },
      },
      applyData
    );
    return () => {};
  }, [fetchAllUsers, setUserDataState, searchState]);

  return (
    <div className={classes["table-container"]}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          autoHeight
          rows={props.userData.map((user) => {
            return {
              id: user.email,
              ...user,
              last_login: formatDate(Date.parse(user.last_login)),
              actions: {
                confirmed: user.confirmed,
                active: user.active,
                email: user.email,
                firstName: user.first_name,
                lastName: user.last_name,
                location: user.location,
                team: user.team,
                roleName: user.role_name
              },
            };
          })}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </div>
    </div>
  );
};

export default UserTable;
