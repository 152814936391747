import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  token: localStorage.getItem("token"),
  isLoggedIn: localStorage.getItem("token") ? true : false,
  userState: {}
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      localStorage.setItem("token", action.payload.token);
      state.token = action.payload.token;
      state.isLoggedIn = true;
    },
    logout(state) {
      localStorage.removeItem("token");
      state.token = null;
      state.isLoggedIn = false;
    },
    update(state, action) {
      state.userState = action.payload
    }
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
