import { useState } from "react";
import useRequest from "../hooks/use-request";
import classes from "./ActivateButton.module.css";

const ActivateButton = (props) => {
  const [activate, setActivate] = useState(props.activate);
  const { sendRequest: activateUser } = useRequest();
  const url = activate
    ? `/admin/activate-user/${props.userEmail}`
    : `/admin/deactivate-user/${props.userEmail}`;

  const onClickHandler = (event) => {
    event.preventDefault();
    activateUser({
      url: url,
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
    });
    setActivate(!activate);
  };

  return (
    <button
      className={activate ? classes["activate-button"] : classes["deactivate-button"]}
      onClick={onClickHandler}
    >{activate ? "REACTIVATE" : "DEACTIVATE"}</button>
  );
};

export default ActivateButton;
