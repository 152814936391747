import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/ui";
import useRequest from "../../hooks/use-request";
import {
  FormWrapper,
  FormInputWrapper,
  FormSubmit,
} from "../../UI/Form/FormItems";

const InviteUser = (props) => {
  const dispatch = useDispatch();
  const { sendRequest: inviteUser } = useRequest();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const submitHandler = (data) => {
    inviteUser(
      {
        url: `/admin/invite-user`,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: { email: data.email },
      },
      (data) => {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Successfully Invited User!",
            message: "",
          })
        );
      }
    );
  };

  return (
    <FormWrapper title="Invite User" onSubmitHandler={handleSubmit(submitHandler)}>
      <FormInputWrapper label="Email" errorState={errors.email}>
        <input
          type="email"
          placeholder={"example@infinity-biologix.com"}
          {...register("email", {
            required: "*required",
            maxLength: {
              value: 64,
              message: "error message",
            },
          })}
        ></input>
      </FormInputWrapper>
      <FormSubmit linkTo="/users" linkLabel="Back" submitLabel="Invite" />
    </FormWrapper>
  );
};

export default InviteUser;
