import Wrap from "../UI/Wrap";
import Label from "../UI/Label";
import classes from "./UserManagement.module.css";
import UserTable from "./Table/UserTable";
import SearchBar from "../UI/SearchBar";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const UserManagement = () => {
  const [userData, setUserData] = useState([]);
  const [searchState, setSearchState] = useState(false);

  return (
    <section className={classes.main}>
      <Wrap>
        <Label>
          <h3>User Management</h3>
        </Label>
        <div className={classes["search-row"]}>
          <div className={classes["user-actions"]}>
            <Link to={"/users/new-user"}>
              <button>New User</button>
            </Link>
            <Link to={"/users/invite-user"}>
              <button>Invite User</button>
            </Link>
          </div>
          <SearchBar
            setUserDataState={setUserData}
            setSearchState={setSearchState}
            searchState={searchState}
          ></SearchBar>
        </div>
        <UserTable
          setUserDataState={setUserData}
          userData={userData}
          searchState={searchState}
        ></UserTable>
      </Wrap>
    </section>
  );
};

export default UserManagement;
