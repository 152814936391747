import classes from "./InviteUser.module.css";
import InviteUserForm from "./InviteUserForm";

const InviteUser = (props) => {
  return (
    <section className={classes.main}>
      <InviteUserForm />
    </section>
  );
};

export default InviteUser;
