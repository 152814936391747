import classes from "./Notification.module.css";
import { TiDelete } from "react-icons/ti";
import { uiActions } from "../../store/ui";
import { useDispatch } from "react-redux";

const Notification = (props) => {
  const dispatch = useDispatch();

  let specialClasses = "";

  if (props.status === "error") {
    specialClasses = classes.error;
  }
  if (props.status === "success") {
    specialClasses = classes.success;
  }

  const cssClasses = `${classes.notification} ${specialClasses}`;

  const onClickHandler = (event) => {
    dispatch(uiActions.reset())
  };

  return (
    <section className={cssClasses}>
      <h2>{props.title}</h2>
      <div className={classes.message}>
        <p>{props.message}</p>
        <TiDelete className={classes["delete-icon"]} onClick={onClickHandler} />
      </div>
    </section>
  );
};

export default Notification;
