import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Label from "../Label";
import Wrap from "../Wrap";
import classes from "./FormItems.module.css";
import { TiWarningOutline } from "react-icons/ti";

export const FormWrapper = (props) => {
  return (
    <Wrap>
      <Label>
        <h3>{props.title}</h3>
      </Label>
      <form onSubmit={props.onSubmitHandler}>{props.children}</form>
    </Wrap>
  );
};

export const FormInputWrapper = (props) => {
  return (
    <>
      <div className={classes.control}>
        <label>{props.label}</label>
        <div
          className={
            props.errorState
              ? classes["input-container-error"]
              : classes["input-container"]
          }
        >
          {props.errorState && !props.multiValidation && (
            <InputCornerError message={props.errorState.message} />
          )}
          {props.children}
        </div>
      </div>
      {props.errorState && props.multiValidation && (
        <InputError message={props.errorState.message} />
      )}
    </>
  );
};

export const InputError = (props) => {
  return (
    <div className={classes["error-container"]}>
      <div className={classes["submit-space"]}></div>
      <div className={classes["error-content"]}>
        <TiWarningOutline className={classes.icon} />
        <p> {props.message}</p>
      </div>
    </div>
  );
};

export const InputCornerError = (props) => {
  return (
    <div className={classes["error-container-corner"]}>
      <p>{props.message}</p>
    </div>
  );
};

export const FormSubmit = (props) => {
  return (
    <div className={classes.actions}>
      <div className={classes["submit-space"]}></div>
      <div className={classes["buttons"]}>
        {props.linkTo && (
          <Link className={classes["link-button"]} to={props.linkTo}>
            {props.linkLabel}
          </Link>
        )}
        <button>{props.submitLabel}</button>
      </div>
    </div>
  );
};
