import Card from "../UI/Card";
import classes from "./AuthForm.module.css";
import ibxLogo from "../../assets/ibx-logo.png";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { authenticateUser } from "../../store/auth-actions";

const AuthForm = () => {
  const dispatch = useDispatch();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const submitHandler = (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    dispatch(
      authenticateUser({
        username: enteredEmail,
        password: enteredPassword,
      })
    );
  };

  return (
    <Card>
        <div className={classes["ibx-logo"]}>
          <img src={ibxLogo} alt="ibx-logo"></img>
        </div>
        <form onSubmit={submitHandler}>
          <div className={classes.control}>
            <input
              type="email"
              id="email"
              placeholder="email"
              ref={emailInputRef}
              required
            />
          </div>
          <div className={classes.control}>
            <input
              type="password"
              id="password"
              placeholder="password"
              ref={passwordInputRef}
              required
            />
          </div>
          <div className={classes.actions}>
            <button>Login</button>
          </div>
        </form>
    </Card>
  );
};

export default AuthForm;
