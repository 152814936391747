import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/ui";
import useRequest from "../../hooks/use-request";
import {
  FormInputWrapper,
  FormSubmit,
  FormWrapper,
} from "../../UI/Form/FormItems";

const roleNames = [
  "Submission",
  "Extraction",
  "Comm",
  "PCR",
  "Manager",
  "Superuser",
];

const labNames = ["NJ", "MN"];

const teamNames = ["Bioinformatics", "Unassigned"];

const NewUserForm = (props) => {
  const dispatch = useDispatch();
  const { sendRequest: newUser } = useRequest();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const submitHandler = (data) => {
    newUser(
      {
        url: `/admin/new-user`,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: {
          email: data.email,
          first_name: data.firstName,
          last_name: data.lastName,
          middle_name: "",
          password: data.password,
          phone: data.phone,
          location: data.location,
          team: data.team,
          role_name: data.roleName,
        },
      },
      (data) => {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Successfully Updated User!",
            message: "",
          })
        );
      }
    );
  };

  console.log(errors.firstName);

  return (
    <FormWrapper
      title="New User"
      onSubmitHandler={handleSubmit(submitHandler)}
    >
      <FormInputWrapper label="Email" errorState={errors.email}>
        <input
          type="email"
          {...register("email", {
            required: "*required",
            maxLength: {
              value: 64,
              message: "error message",
            },
          })}
        ></input>
      </FormInputWrapper>
      <FormInputWrapper label="First Name" errorState={errors.firstName}>
        <input
          type="text"
          {...register("firstName", {
            required: "*required",
            maxLength: {
              value: 16,
              message: "error message",
            },
          })}
        ></input>
      </FormInputWrapper>
      <FormInputWrapper label="Last Name" errorState={errors.lastName}>
        <input
          type="text"
          {...register("lastName", {
            required: "*required",
            maxLength: {
              value: 16,
              message: "error message",
            },
          })}
        ></input>
      </FormInputWrapper>
      <FormInputWrapper label="Phone" errorState={errors.phone}>
        <input
          type="tel"
          {...register("phone", {
            required: "*required",
            minLength: {
              value: 6,
              message: "error message",
            },
            maxLength: {
                value: 12,
                message: "error message",
              },
          })}
        ></input>
      </FormInputWrapper>
      <FormInputWrapper label="Location" errorState={errors.location}>
        <select
          {...register("location", {
            required: "*required",
          })}
        >
          <option value="" selected disabled hidden>
            Select Location
          </option>
          {labNames.map((labName) => (
            <option key={labName} value={labName}>
              {labName}
            </option>
          ))}
        </select>
      </FormInputWrapper>
      <FormInputWrapper label="Team" errorState={errors.team}>
        <select
          {...register("team", {
            required: "*required",
          })}
        >
          <option value="" selected disabled hidden>
            Select Team
          </option>
          {teamNames.map((teamName) => (
            <option key={teamName} value={teamName}>
              {teamName}
            </option>
          ))}
        </select>
      </FormInputWrapper>
      <FormInputWrapper label="Role" errorState={errors.roleName}>
        <select
          {...register("roleName", {
            required: "*required",
          })}
        >
          <option value="" selected disabled hidden>
            Select Role
          </option>
          {roleNames.map((roleName) => (
            <option key={roleName} value={roleName}>
              {roleName}
            </option>
          ))}
        </select>
      </FormInputWrapper>
      <FormInputWrapper
        label="Password"
        errorState={errors.password}
        multiValidation={true}
      >
        <input
          type="password"
          {...register("password", {
            required: "Required",
            pattern: {
              value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
              message: "Must contain at least 1 uppercase, lowercase, number and special character",
            },
            maxLength: {
              value: 32,
              message: "Password must not exceed 32 characters",
            },
            minLength: {
              value: 8,
              message: "Password must have at least 8 characters",
            },
          })}
        ></input>
      </FormInputWrapper>
      <FormInputWrapper
        label="Confirm Password"
        errorState={errors.confirmPassword}
        multiValidation={true}
      >
        <input
          type="password"
          {...register("confirmPassword", {
            validate: (value) =>
              value === getValues().password || "The passwords do not match",
          })}
        ></input>
      </FormInputWrapper>
      <FormSubmit linkTo="/users" linkLabel="Back" submitLabel="Create" />
    </FormWrapper>
  );
};

export default NewUserForm;
