import { NavLink } from "react-router-dom";
import classes from "./Sidebar.module.css";
import { VscOrganization } from "react-icons/vsc";
import { IoAnalytics, IoPeopleCircleOutline } from "react-icons/io5";
import { BiTestTube } from "react-icons/bi";
import ibxLogo from "../../assets/ibx-logo.svg";

const Sidebar = (props) => {
  const navOptions = [
    {
      id: "analytics",
      to: "/analytics",
      icon: <IoAnalytics className={classes.icon} />,
      title: "ANALYTICS",
    },
    {
      id: "users",
      to: "/users",
      icon: <IoPeopleCircleOutline className={classes.icon} />,
      title: "USERS",
    },
    {
      id: "clients",
      to: "/clients",
      icon: <BiTestTube className={classes.icon} />,
      title: "CLIENTS",
    },
    {
      id: "organization",
      to: "/organization",
      icon: <VscOrganization className={classes.icon} />,
      title: "ORGANIZATION",
    },
  ];

  return (
    <>
      <div className={classes["sidebar-cont"]}>
        <nav className={classes.sidebar}>
          <div className={classes["ibx-logo"]}>
            <img src={ibxLogo} alt="ibx-logo"></img>
          </div>
          <ul>
            {navOptions.map((navObj) => (
              <li key={navObj.id}>
                <NavLink
                  activeClassName={classes.active}
                  className={classes["nav-button"]}
                  to={navObj.to}
                >
                  {navObj.icon}
                  <span>{navObj.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
