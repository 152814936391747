import { useEffect, useState } from "react";
import useRequest from "../hooks/use-request";
import classes from "./SearchBar.module.css";

const SearchBar = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const {sendRequest: fetchUsers} = useRequest()
  const {setSearchState, setUserDataState} = props

  const onInputHandler = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchTerm.length >= 3) {
      setSearchState(true);
      fetchUsers({
        url: `/admin/query-users/${searchTerm}`,
        headers: {Accept: "application/json"}
      }, (data) => setUserDataState(data))
    } else if (searchTerm.length < 3) {
      setSearchState(false);
    }
    return () => {
    };
  }, [fetchUsers, setSearchState, setUserDataState, searchTerm]);

  return (
    <form className={classes["search-form"]}>
      <input
        type="text"
        placeholder="Find User ..."
        className={classes["search-input"]}
        onChange={onInputHandler}
      ></input>
    </form>
  );
};

export default SearchBar;
