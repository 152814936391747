import classes from "./NewUser.module.css";
import NewUserForm from "./NewUserForm";

const NewUser = (props) => {
  return (
    <section className={classes.main}>
        <NewUserForm />
    </section>
  );
};

export default NewUser;