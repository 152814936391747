import AuthForm from "../components/Auth/AuthForm";
import classes from "./LoginPage.module.css";

const Login = (props) => {
  return (
    <div className={classes['auth-form-section']}>
      <AuthForm />
    </div>
  );
};

export default Login;
