import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "./pages/LoginPage";
import EditUserPage from "./pages/EditUserPage";
import NewUserPage from "./pages/NewUserPage";
import Users from "./pages/Users";
import Notification from "./components/UI/Notification";
import Layout from "./components/Layout/Layout";
import { useEffect } from "react";
import { authActions } from "./store/auth";
import InviteUserPage from "./pages/InviteUserPage";

function App() {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.ui.notification);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const testToken = async () => {
      const response = await fetch(
        "https://auth.infinity-biologix-services.com/login/test-token",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseBody = await response.json()
      if (!response.ok) {
        dispatch(authActions.logout())
      } else {
        dispatch(authActions.update(responseBody))
      }
    };
    if (token) {
      testToken();
    }
    return;
  }, [token, dispatch]);

  return (
    <>
      {notification && (
        <Notification
          type={notification.type}
          status={notification.status}
          title={notification.title}
          message={notification.message}
        />
      )}
      <Route path="/login" exact>
        {!isLoggedIn ? <Login></Login> : <Redirect to="/"></Redirect>}
      </Route>
      {isLoggedIn ? (
        <Layout>
          <Switch>
            <Route path="/users" exact>
              <Users></Users>
            </Route>
            <Route path="/users/edit/:email" component={EditUserPage} />
            <Route path="/users/new-user" component={NewUserPage} exact/>
            <Route path="/users/invite-user" component={InviteUserPage} exact/> 
            <Route path="*">
              <Redirect to="/"></Redirect>
            </Route>
          </Switch>
        </Layout>
      ) : (
        <Redirect to="/login"></Redirect>
      )}
    </>
  );
}

export default App;
