import { uiActions } from "./ui";
import { authActions } from "./auth";

export const authenticateUser = (userCredentials) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(
        "https://auth.infinity-biologix-services.com/login/access-token",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          credentials: 'include',
          body: new URLSearchParams(userCredentials).toString(),
        }
      );
      const responseBody = await response.json()
      if (!response.ok) {
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Login Error!",
            message: responseBody.detail,
          })
        );
      } else {
        dispatch(uiActions.reset());
        dispatch(
          authActions.login({
            token: responseBody.access_token,
          })
        );
      }
    };
    try {
      await sendRequest();
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Login Error!",
          message: error.message,
        })
      );
    }
  };
};
