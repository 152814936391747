import classes from "./EditUser.module.css";
import EditUserForm from "./EditUserForm";

const EditUser = (props) => {
  return (
    <section className={classes.main}>
      <EditUserForm userData={props.userData}></EditUserForm>
    </section>
  );
};

export default EditUser;
